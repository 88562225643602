import * as React from 'react';
import Link from 'next/link';
import { AppMenu, Flex, Text } from '@ghq-abi/design-system';

import { useNav } from '~/app/contexts/NavContext';
import { RouteObject } from '~/app/types';
import { BackArrowIcon, OurAppsIcon } from '~/shared/components/icons';
import { HamburguerIcon } from '~/shared/components/icons/HamburgerIcon';

export function MobileMenu() {
  const {
    isAppMenuOpen,
    subMenuOpen,
    menuHeaderItem,
    menuItems,
    footerItems,
    toggleAppMenu,
    checkIfIsActive,
    toggleSubMenu,
  } = useNav();

  const onMenuItemClick = (item: RouteObject) => {
    if (item.routeComponents?.length) {
      toggleSubMenu(item);
    } else {
      toggleSubMenu(null);
      toggleAppMenu();
    }
  };

  return (
    <AppMenu open={isAppMenuOpen}>
      <AppMenu.Trigger asChild>
        <AppMenu.MenuIconButton
          onClick={toggleAppMenu}
          icon={<HamburguerIcon />}
        />
      </AppMenu.Trigger>
      <AppMenu.Content
        overlayIsActive
        closeMenu={() => {
          toggleAppMenu();
          toggleSubMenu(null);
        }}
      >
        <AppMenu.Header css={{ p: 0 }}>
          {subMenuOpen ? (
            <Flex
              align="center"
              gap="sm"
              css={{ width: '$full', height: '$appHeaderHeight', p: '$2' }}
            >
              <AppMenu.MenuIconButton
                onClick={() => toggleSubMenu(null)}
                icon={<BackArrowIcon />}
              />
              <Text css={{ fontWeight: '$medium' }}>
                {subMenuOpen[0]?.title || ''}
              </Text>
            </Flex>
          ) : (
            <Flex
              align="center"
              justify="between"
              css={{ width: '$full', height: '$appHeaderHeight', p: '$2' }}
            >
              <Text
                css={{
                  fontSize: '$2xl',
                  fontWeight: '$bold',
                  background:
                    'linear-gradient(90deg, #D3A238 3.33%, #F5E003 40.91%)',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                  '-webkit-background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent',
                }}
              >
                OPR
              </Text>
              <AppMenu.MenuIconButton
                onClick={() => toggleSubMenu(menuHeaderItem)}
                icon={<OurAppsIcon />}
              />
            </Flex>
          )}
        </AppMenu.Header>
        {subMenuOpen ? (
          <AppMenu.MenuList>
            {subMenuOpen[0]?.routeComponents?.map(subitem => (
              <AppMenu.MenuItem
                key={subitem.key}
                as={Link}
                title={subitem.title}
                route={subitem.path}
                leftIcon={subitem.icon}
                onClick={() => onMenuItemClick(subitem)}
                isSubMenuItem
              />
            ))}
          </AppMenu.MenuList>
        ) : (
          <AppMenu.MenuList>
            {menuItems.map(item => (
              <AppMenu.MenuItem
                key={item.key}
                as={Link}
                route={item.path}
                title={item.title}
                leftIcon={item.icon}
                hasSubmenu={!!item.routeComponents?.length}
                isActive={checkIfIsActive(item.path, item.routeComponents)}
                onClick={() => onMenuItemClick(item)}
              />
            ))}
          </AppMenu.MenuList>
        )}
        <AppMenu.Footer>
          {footerItems.map(item => (
            <AppMenu.MenuItem
              key={item.key}
              as={Link}
              title={item.title}
              route={item.path}
              hasSubmenu={!!item?.routeComponents?.length}
              leftIcon={item.icon}
              isActive={checkIfIsActive(item.path, item.routeComponents)}
              onClick={() => onMenuItemClick(item)}
            />
          ))}
        </AppMenu.Footer>
      </AppMenu.Content>
    </AppMenu>
  );
}
