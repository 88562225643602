import { useQuery } from '@tanstack/react-query';
import type { AxiosRequestConfig } from 'axios';

import { oprApi } from '~/shared/services/api';
import { clearParams } from '~/shared/utils/clearParams';

import { EmployeeSimple } from '../types';

export type GetEmployeesTalentPoolsAPIResponse = {
  data: EmployeeSimple[];
  count: number;
  countEligible: number;
  countNotEligible: number;
};

export type GetEmployeesTalentPoolsFilterParams = {
  employeeNameOrId?: string;
};

export type GetEmployeesTalentPoolParams =
  GetEmployeesTalentPoolsFilterParams & {
    page?: number;
    limit?: number;
    orderField?: string;
    orderValue?: 'asc' | 'desc';
  };

export const EMPLOYEES_TALENT_POOL_PER_PAGE = 100;

async function searchEmployees(
  { page, limit, ...params }: GetEmployeesTalentPoolParams,
  config: AxiosRequestConfig = {}
) {
  const clearedParams = clearParams(params);

  if (
    clearedParams.eligibilityView &&
    clearedParams.eligibilityView !== 'all'
  ) {
    clearedParams.eligible = String(
      clearedParams.eligibilityView === 'eligible'
    );
  }

  delete clearedParams.eligibilityView;

  const response = await oprApi.get<GetEmployeesTalentPoolsAPIResponse>(
    'employees/talent-pool',
    {
      params: {
        ...clearedParams,
        limit,
        page,
      },
      ...config,
    }
  );

  return response.data;
}

export const GET_EMPLOYEES_TALENT_POOL_QUERY_KEY = 'useGetEmployeesEligibility';

export function useGetEmployeesTalentPool(
  params: GetEmployeesTalentPoolParams = {}
) {
  return useQuery({
    queryKey: [GET_EMPLOYEES_TALENT_POOL_QUERY_KEY, params],
    queryFn: () => searchEmployees(params),
  });
}
