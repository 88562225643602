import { styled } from '@ghq-abi/design-system';

export const StyledHeader = styled('header', {
  height: '$appHeaderHeight',
  width: '$full',
  backgroundColor: '$white',
  borderBottom: '1px solid $gray410',
  px: '$4',
  zIndex: '$sticky',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$md',

  position: 'fixed',
  top: 0,

  '@lg': {
    px: '$6',
  },

  variants: {
    isMenuOpen: {
      true: {
        '@lg': {
          left: '$appMenuOpenWidth',
          width:
            'calc($full - $appMenuOpenWidth - var(--removed-body-scroll-bar-size, 0px))',
        },
      },
      false: {
        '@lg': {
          left: '$appMenuClosedWidth',
          width:
            'calc($full - $appMenuClosedWidth - var(--removed-body-scroll-bar-size, 0px))',
        },
      },
    },
  },
});

export const StyledBanner = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FF0000',
  width: '270px',
  height: '40px',
  borderRadius: '$2',
  gap: '$sm',
  color: '$white',
});
