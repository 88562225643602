// services/featureFlags.ts
import { GetServerSidePropsContext } from 'next';

import { ApiBuilder } from '~/shared/services/api';

import { GetFeatureFlagsAPIResponse } from './useGetFeatureFlags';

export async function fetchFeatureFlags(
  context?: GetServerSidePropsContext
): Promise<Record<string, boolean>> {
  const api = ApiBuilder.getInstance().mount(
    {
      baseURL: process.env.NEXT_PUBLIC_OPR_API_URL,
    },
    context
  );

  const response = await api.get<GetFeatureFlagsAPIResponse>(
    'feature-flag-management',
    {}
  );

  if (response.data?.length) {
    return response.data?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.name]: curr.status,
      };
    }, {});
  }

  return {};
}
