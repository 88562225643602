import React from 'react';
import { Flex } from '@ghq-abi/design-system';

import { useNav } from '~/app/contexts/NavContext';
import { useProfilePicture } from '~/app/contexts/ProfilePictureContext';
import { EditProfilePictureDialog } from '~/entities/Employee/ui/dialogs';
import { useUser } from '~/shared/auth/useUser';

import { HeaderActions } from '../HeaderActions';
import { LanguageDropdownHeader } from '../LanguageDropdown';
import { ModulesDropdown } from '../ModulesDropdown';
import { UserDropdown } from '../UserDropdown';

import { EnvBanner } from './HeaderBanner';
import { StyledHeader } from './styles';

export function Header() {
  const user = useUser();
  const { isOpen, toggleOpen } = useProfilePicture();
  const { isAppMenuOpen } = useNav();

  return (
    <StyledHeader isMenuOpen={isAppMenuOpen}>
      <HeaderActions />
      {user.employeeId && !user.isProxied && (
        <EditProfilePictureDialog
          isOpen={isOpen}
          toggleOpen={toggleOpen}
          employeeId={user.employeeId}
        />
      )}
      <EnvBanner />
      <Flex gap="md" align="center">
        {user && <LanguageDropdownHeader currentLanguage={user.language} />}
        <ModulesDropdown />
        {user && <UserDropdown user={user} />}
      </Flex>
    </StyledHeader>
  );
}
