import { AlertOutlineIcon } from '@ghq-abi/design-system-icons';

import { getEnv } from '~/shared/utils/environments';

import { StyledBanner } from './styles';

export const EnvBanner = () => {
  const env = getEnv();

  if (env === 'dev') {
    return (
      <StyledBanner>
        <AlertOutlineIcon color="white" />
        Development Environment
      </StyledBanner>
    );
  }

  if (env === 'staging') {
    return (
      <StyledBanner>
        <AlertOutlineIcon color="white" />
        Staging Environment
      </StyledBanner>
    );
  }

  return null;
};
