import React, { createContext, useContext, useEffect, useState } from 'react';

import { fetchFeatureFlags } from '~/entities/FeatureFlags/api/fetchFeatureFlagsForContext';

const uuidRegex =
  '[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}';

const pagesPaths = {
  home: '/home',
  dashboardPersonal: '/dashboard/me',
  dashboardManager: new RegExp(`/dashboard/${uuidRegex}$`),
  eligibilityCriteriaExceptions: '/eligibility-criteria/exceptions',
  eligibilityCriteriaSetup: '/eligibility-criteria/setup',
  managerAssessment: '/manager-assessment',
  managerFeedback: '/manager-feedback',
  roleSetting: '/role-setting',
  oprProcess: '/opr-process',
  translations: '/translations',
  feedbackConfiguration: '/feedback-configuration',
  meetingsList: '/meetings',
  meetingCreate: '/meetings/create',
  meetingDetails: new RegExp(`/meetings/${uuidRegex}$`),
  meetingShapes: new RegExp(`/meetings/${uuidRegex}/shapes`),
  meetingEdit: new RegExp(`/meetings/${uuidRegex}/edit`),
  meetingOverview: new RegExp(`/meetings/${uuidRegex}/overview`),
  meetingOverviewTalentPool: new RegExp(
    `/meetings/${uuidRegex}/overview/talent-pool`
  ),
  proxy: '/proxy',
  talentCardPersonal: '/talent-card/me',
  talentCardManager: new RegExp(`/talent-card/${uuidRegex}$`),
  reportTalentSearch: '/reports/talent-search',
  reportMyOrganization: '/reports/my-organization',
  reportTalentPerformance: '/reports/talent-performance',
  reportTalentPool: '/reports/talent-pool',
  reportOpr: '/reports/opr',
  reportMeetingDetails: '/reports/meeting-details',
  documents: '/documents',
  funcManagerAssessmentFreeze: 'func/manager-assessment-freeze',
  dashboardPersonalV2: '/dashboard/v2',
  succession: '/succession',
  talentPoolManagement: '/talent-pool-management',
  featureFlags: '/feature-flags',
} as const;

export type PagesPathsKeys = keyof typeof pagesPaths;

export type Flags = Record<
  PagesPathsKeys,
  { active: boolean; skippable: boolean }
>;

type FeatureFlagsContextType = {
  flags: Flags;
  setFlags: React.Dispatch<React.SetStateAction<Flags>>;
  rawFlags: Record<string, boolean>;
  setRawFlags: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  transformRawFlagsIntoFlags: (
    rawFlags: Record<string, boolean>
  ) => Record<PagesPathsKeys, { active: boolean; skippable: boolean }>;
};

function transformRawFlagsIntoFlags(rawFlags: Record<string, boolean>) {
  const flags = {
    home: {
      active: rawFlags?.PAGE_HOME_ENABLED || false,
      skippable: true,
    },
    oprProcess: {
      active: rawFlags?.PAGE_HOME_ENABLED || false,
      skippable: false,
    },
    dashboardPersonal: {
      active: rawFlags?.PAGE_DASHBOARD_PERSONAL_ENABLED || false,
      skippable: false,
    },
    dashboardManager: {
      active: rawFlags?.PAGE_DASHBOARD_MANAGER_ENABLED || false,
      skippable: false,
    },
    eligibilityCriteriaExceptions: {
      active: rawFlags?.PAGE_ELIGIBILITY_CRITERIA_EXCEPTIONS_ENABLED || false,
      skippable: true,
    },
    eligibilityCriteriaSetup: {
      active: rawFlags?.PAGE_ELIGIBILITY_CRITERIA_SETUP_ENABLED || false,
      skippable: true,
    },
    managerAssessment: {
      active: rawFlags?.PAGE_MANAGER_ASSESSMENT_ENABLED || false,
      skippable: true,
    },
    managerFeedback: {
      active: rawFlags?.PAGE_MANAGER_FEEDBACK_ENABLED || false,
      skippable: true,
    },
    roleSetting: {
      active: rawFlags?.PAGE_ROLE_SETTING_ENABLED || false,
      skippable: true,
    },
    translations: {
      active: rawFlags?.PAGE_TRANSLATIONS_ENABLED || false,
      skippable: true,
    },
    meetingsList: {
      active: rawFlags?.PAGE_MEETINGS_LIST_ENABLED || false,
      skippable: true,
    },
    meetingCreate: {
      active: rawFlags?.PAGE_MEETING_CREATE_ENABLED || false,
      skippable: true,
    },
    meetingDetails: {
      active: rawFlags?.PAGE_MEETING_DETAILS_ENABLED || false,
      skippable: true,
    },
    meetingShapes: {
      active: rawFlags?.PAGE_MEETING_SHAPES_ENABLED || false,
      skippable: true,
    },
    meetingEdit: {
      active: rawFlags?.PAGE_MEETING_EDIT_ENABLED || false,
      skippable: true,
    },
    meetingOverview: {
      active: rawFlags?.PAGE_MEETING_OVERVIEW_ENABLED || false,
      skippable: true,
    },
    meetingOverviewTalentPool: {
      active: rawFlags?.PAGE_MEETING_OVERVIEW_TALENT_POOL_ENABLED || false,
      skippable: true,
    },
    proxy: {
      active: rawFlags?.PAGE_PROXY_ENABLED || false,
      skippable: true,
    },
    talentCardPersonal: {
      active: rawFlags?.PAGE_TALENT_CARD_PERSONAL_ENABLED || false,
      skippable: true,
    },
    talentCardManager: {
      active: rawFlags?.PAGE_TALENT_CARD_MANAGER_ENABLED || false,
      skippable: true,
    },
    reportTalentSearch: {
      active: rawFlags?.PAGE_REPORT_TALENT_SEARCH_ENABLED || false,
      skippable: true,
    },
    reportMyOrganization: {
      active: rawFlags?.PAGE_REPORT_MY_ORGANIZATION_ENABLED || false,
      skippable: true,
    },
    reportTalentPerformance: {
      active: rawFlags?.PAGE_REPORT_TALENT_PERFORMANCE_ENABLED || false,
      skippable: true,
    },
    reportTalentPool: {
      active: rawFlags?.PAGE_REPORT_TALENT_POOL_ENABLED || false,
      skippable: true,
    },
    reportOpr: {
      active: rawFlags?.PAGE_REPORT_OPR_ENABLED || false,
      skippable: false,
    },
    reportMeetingDetails: {
      active: rawFlags?.PAGE_REPORT_MEETING_DETAILS_ENABLED || false,
      skippable: true,
    },
    documents: {
      active: rawFlags?.PAGE_DOCUMENTS_ENABLED || false,
      skippable: true,
    },
    feedbackConfiguration: {
      active: rawFlags?.FEEDBACK_CONFIGURATION_ENABLED || false,
      skippable: true,
    },
    funcManagerAssessmentFreeze: {
      active: rawFlags?.FUNC_MANAGER_ASSESSMENT_PROCESS_FREEZE || false,
      skippable: true,
    },
    dashboardPersonalV2: {
      active: rawFlags?.PAGE_DASHBOARD_V2_PERSONAL_ENABLED || false,
      skippable: false,
    },
    succession: {
      active: rawFlags?.PAGE_SUCCESSION || false,
      skippable: false,
    },
    talentPoolManagement: {
      active: rawFlags?.PAGE_TALENT_POOL_MANAGEMENT || false,
      skippable: false,
    },
    featureFlags: {
      active: true,
      skippable: false,
    },
  };

  return flags;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextType>({
  flags: transformRawFlagsIntoFlags({}),
  setFlags: () => {},
  rawFlags: {},
  setRawFlags: () => {},
  transformRawFlagsIntoFlags: () => ({} as Flags),
});

export function FeatureFlagsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [rawFlags, setRawFlags] = useState<Record<string, boolean>>({});
  const [flags, setFlags] = useState<Flags>(
    transformRawFlagsIntoFlags(rawFlags)
  );

  useEffect(() => {
    async function loadInitialFlags() {
      const initialFlags = await fetchFeatureFlags();
      setRawFlags(initialFlags);
    }

    loadInitialFlags();
  }, []);

  useEffect(() => {
    const transformedFlags = transformRawFlagsIntoFlags(rawFlags);
    setFlags(transformedFlags);
  }, [rawFlags]);

  const contextValue = React.useMemo(
    () => ({
      flags,
      setFlags,
      rawFlags,
      setRawFlags,
      transformRawFlagsIntoFlags,
    }),
    [flags, setFlags, rawFlags, setRawFlags]
  );

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}
