import { useCallback, useMemo } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BsPatchCheck } from 'react-icons/bs';
import {
  CarbonAnalyticsIcon,
  ClipboardCheckLargeIcon,
  EarMarkPersonIcon,
  GridIcon,
  HouseIcon,
  MenuDownloadIcon,
  MenuGearIcon,
  MenuPersonBadgeIcon,
  MenuPersonCheckIcon,
  PersonUpIcon,
} from '@ghq-abi/design-system-icons';
import { useTranslate } from '@tolgee/react';
import { ActionEnum, ModuleEnum } from 'abi-opr-common-types';

import { useAbility } from '~/app/contexts/AbilityContext';
import {
  PagesPathsKeys,
  useFeatureFlags,
} from '~/app/contexts/FeatureFlagsContext';
import { RouteObject } from '~/app/types';
import { getEnv } from '~/shared/utils/environments';

export function useMenuRoutes() {
  const { t } = useTranslate('web-app');
  const ability = useAbility();
  const { flags } = useFeatureFlags();

  const hasAuthorizedFlags = useCallback(
    (authorizedFlags: PagesPathsKeys | PagesPathsKeys[]) => {
      if (
        typeof authorizedFlags === 'string' &&
        !flags[authorizedFlags].active
      ) {
        return false;
      }

      if (
        Array.isArray(authorizedFlags) &&
        !authorizedFlags.some(flag => flags[flag].active)
      ) {
        return false;
      }

      return true;
    },
    [flags]
  );

  const { menuRoutes, footerRoutes } = useMemo(() => {
    const menuRoutesList: RouteObject[] = [];
    const footerRoutesList: RouteObject[] = [];

    if (hasAuthorizedFlags('home') && ability.can('view', 'Home')) {
      menuRoutesList.push({
        key: 'home',
        icon: <HouseIcon />,
        path: '/home',
        title: t('home.title'),
      });
    }

    if (hasAuthorizedFlags('talentCardPersonal')) {
      menuRoutesList.push({
        key: 'talentCardPersonal',
        icon: <MenuPersonBadgeIcon />,
        path: '/talent-card/me',
        title: t('menu.my_talent_card'),
      });
    }

    if (
      hasAuthorizedFlags('dashboardPersonalV2') &&
      ability.can('view', 'PersonalDashboardV2')
    ) {
      menuRoutesList.push({
        key: 'dashboardPersonalV2',
        icon: <GridIcon />,
        path: '/dashboard/v2',
        title: t('My Dashboard'),
      });
    }

    if (hasAuthorizedFlags('oprProcess') && ability.can('view', 'OprProcess')) {
      const meetingsComponents = [];

      if (
        hasAuthorizedFlags('managerAssessment') &&
        ability.can('view', 'ManagerAssessment')
      ) {
        meetingsComponents.push({
          key: 'managerAssessment',
          icon: <MenuPersonCheckIcon />,
          path: '/manager-assessment',
          title: t('manager_assessment.title'),
        });
      }

      if (ability.can('list', 'Meeting') && flags.meetingsList.active) {
        meetingsComponents.push({
          key: 'meetingsList',
          title: t('documents.modules.meetings'),
          path: '/meetings',
          routeComponents: [
            {
              key: 'meetingsList',
              title: t('meetings.list.link'),
              path: '/meetings/list',
            },
            {
              key: 'meetingCreate',
              title: t('meetings.create.link'),
              path: '/meetings/create',
            },
          ],
        });
      }

      if (
        hasAuthorizedFlags('succession') &&
        ability.can('view', 'Succession')
      ) {
        meetingsComponents.push({
          key: 'succession',
          icon: <PersonUpIcon />,
          path: '/succession',
          title: t('menu.succession'),
        });
      }

      if (
        hasAuthorizedFlags('managerFeedback') &&
        ability.can('list', 'GetEmployeeFeedbackModelView')
      ) {
        meetingsComponents.push({
          key: 'managerFeedback',
          icon: <BsPatchCheck />,
          path: '/manager-feedback',
          title: t('manager_feedback.title'),
        });
      }
      menuRoutesList.push({
        key: 'oprProcess',
        title: t('OPR Process'),
        icon: <EarMarkPersonIcon />,
        path: '/',
        routeComponents: meetingsComponents,
      });
    }

    if (
      hasAuthorizedFlags('dashboardPersonal') &&
      ability.can('view', 'PersonalDashboard')
    ) {
      menuRoutesList.push({
        key: 'dashboardPersonal',
        icon: <CarbonAnalyticsIcon />,
        path: '/dashboard/me',
        title: t('menu.opr_dashboard'),
      });
    }

    if (
      hasAuthorizedFlags([
        'reportTalentSearch',
        'reportMyOrganization',
        'reportTalentPerformance',
        'reportMeetingDetails',
      ]) &&
      (ability.can('view', 'ReportTalentSearch') ||
        ability.can('view', 'ReportMyOrganization') ||
        ability.can('view', 'ReportTalentPerformance') ||
        ability.can('view', 'ReportMeetingDetails'))
    ) {
      const reportsComponents = [];

      if (
        ability.can('view', 'ReportMyOrganization') &&
        flags.reportMyOrganization.active
      ) {
        reportsComponents.push({
          key: 'reportMyOrganization',
          path: '/reports/my-organization',
          title: t('menu.my_organization'),
        });
      }

      if (
        ability.can('view', 'ReportMeetingDetails') &&
        flags.reportMeetingDetails.active
      ) {
        reportsComponents.push({
          key: 'reportMeetingDetails',
          path: '/reports/meeting-details',
          title: t('menu.meeting_details_report'),
        });
      }

      if (
        ability.can('view', 'ReportTalentSearch') &&
        flags.reportTalentSearch.active
      ) {
        reportsComponents.push({
          key: 'reportTalentSearch',
          path: '/reports/talent-search',
          title: t('menu.talent_search'),
        });
      }

      if (
        ability.can('view', 'ReportTalentPerformance') &&
        flags.reportTalentPerformance.active
      ) {
        reportsComponents.push({
          key: 'reportTalentPerformance',
          path: '/reports/talent-performance',
          title: t('menu.talent_performance'),
        });
      }

      if (
        ability.can('view', 'ReportTalentPool') &&
        flags.reportTalentPool.active
      ) {
        reportsComponents.push({
          key: 'reportTalentPool',
          path: '/reports/talent-pool',
          title: t('menu.talent_pool'),
        });
      }

      if (
        ability.can('view', 'ReportOpr') &&
        flags.reportOpr.active &&
        getEnv() !== 'production'
      ) {
        reportsComponents.push({
          key: 'reportOpr',
          path: '/reports/report-opr',
          title: t('menu.report_opr'),
        });
      }

      menuRoutesList.push({
        key: 'reports',
        title: t('menu.reports'),
        icon: <ClipboardCheckLargeIcon />,
        path: '/',
        routeComponents: reportsComponents,
      });
    }

    if (
      (hasAuthorizedFlags([
        'eligibilityCriteriaSetup',
        'eligibilityCriteriaExceptions',
        'roleSetting',
        'feedbackConfiguration',
        'featureFlags',
      ]) &&
        (ability.can('view', 'EligibilityCriteriaSetup') ||
          ability.can('view', 'EligibilityCriteriaExceptions') ||
          ability.can('manage', 'RoleSetting'))) ||
      ability.can('view', 'FeatureFlags')
    ) {
      const settingsComponents = [];
      if (
        ability.can('view', 'EligibilityCriteriaSetup') &&
        flags.eligibilityCriteriaSetup.active
      ) {
        settingsComponents.push({
          key: 'eligibilityCriteriaSetup',
          path: '/eligibility-criteria/setup',
          title: t('eligibility_criteria.setup.title'),
        });
      }

      if (
        ability.can('view', 'EligibilityCriteriaExceptions') &&
        flags.eligibilityCriteriaExceptions.active
      ) {
        settingsComponents.push({
          key: 'eligibilityCriteriaExceptions',
          path: '/eligibility-criteria/exceptions',
          title: t('eligibility_criteria.exceptions.title'),
        });
      }

      if (
        ability.can('view', 'TalentPoolManagement') &&
        flags.talentPoolManagement.active
      ) {
        settingsComponents.push({
          key: 'talentPoolManagement',
          path: '/talent-pool-management',
          title: t('talent_pool_management.title'),
        });
      }

      if (ability.can('manage', 'RoleSetting') && flags.roleSetting.active) {
        settingsComponents.push({
          key: 'roleSetting',
          path: '/role-setting',
          title: t('role_setting.title'),
        });
      }

      if (ability.can('manage', 'FeatureFlags') && flags.featureFlags.active) {
        settingsComponents.push({
          key: 'featureFlags',
          path: '/feature-flags',
          title: t('feature_flags.title'),
          disabled: false,
        });
      }

      if (
        ability.can(ActionEnum.List, ModuleEnum.FeedbackConfiguration) &&
        flags.feedbackConfiguration.active
      ) {
        settingsComponents.push({
          key: 'feedbackConfiguration',
          path: '/feedback-configuration',
          title: t('feedback_configuration.title'),
        });
      }

      menuRoutesList.push({
        key: 'settings',
        title: t('menu.admin_settings'),
        icon: <MenuGearIcon />,
        path: '/',
        routeComponents: settingsComponents,
      });
    }

    if (hasAuthorizedFlags('documents') && ability.can('view', 'Documents')) {
      menuRoutesList.push({
        key: 'documents',
        icon: <MenuDownloadIcon />,
        path: '/documents',
        title: t('menu.download_documents'),
      });
    }

    footerRoutesList.push({
      key: 'knowledgeCenter',
      icon: <AiOutlineQuestionCircle />,
      path: 'https://anheuserbuschinbev.sharepoint.com/sites/OPRSite0',
      title: t('menu.knowledge_center'),
    });

    return { menuRoutes: menuRoutesList, footerRoutes: footerRoutesList };
  }, [ability, flags, hasAuthorizedFlags, t]);

  return { menuRoutes, footerRoutes };
}
