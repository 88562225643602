import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useTranslate } from '@tolgee/react';
import nprogress from 'nprogress';

import { useAbility } from '~/app/contexts/AbilityContext';
import { useAuth } from '~/app/contexts/AuthContext';
import { useGetProfilePicture } from '~/entities/Employee/api/useGetProfilePicture';
import { User } from '~/shared/auth/useUser';
import { Icon } from '~/shared/components';
import { SighOut } from '~/shared/components/icons/SignOut';

type UseUserDropdownProps = {
  user: User;
};

export const useUserDropdown = ({ user }: UseUserDropdownProps) => {
  const { t } = useTranslate('web-app');
  const { data: session } = useSession();
  const router = useRouter();
  const { notifyChangedProxy } = useAuth();

  const isProxying = !!session?.user.px;

  const ability = useAbility();

  async function handleSignOut() {
    try {
      await fetch('/api/destroy-language-cookie');
    } finally {
      signOut({ redirect: false });
    }
  }

  const goToProxy = useCallback(async () => {
    router.push('/proxy');
  }, [router]);

  const handleStopProxy = useCallback(async () => {
    if (!session) {
      return;
    }

    nprogress.start();
    await signIn('register-proxy', {
      user: JSON.stringify({ ...session.user, px: null }),
    });
    notifyChangedProxy();
  }, [notifyChangedProxy, session]);

  const items = useMemo(() => {
    const dropdownItems = [
      {
        label: t('common.sign_out'),
        onSelect: handleSignOut,
        icon: <SighOut />,
      },
    ];

    if (ability.can('proxy', 'User')) {
      dropdownItems.unshift({
        label: t(isProxying ? 'common.stop_proxy' : 'common.proxy_as_user'),
        onSelect: isProxying ? handleStopProxy : goToProxy,
        icon: isProxying ? (
          <Icon.StopProxy size={18} />
        ) : (
          <Icon.Proxy size={18} />
        ),
      });
    }

    return dropdownItems;
  }, [ability, goToProxy, handleStopProxy, isProxying, t]);

  const { data } = useGetProfilePicture(user.globalId ?? '');
  const imageSrc = data ? URL.createObjectURL(data) : undefined;

  const profileInfo = useMemo(() => {
    return {
      name: user.name ?? '',
      urlImage: imageSrc,
      zone: user.zone ?? '',
    };
  }, [imageSrc, user.name, user.zone]);

  return {
    items,
    profileInfo,
  };
};
